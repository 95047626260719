import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const LogIn = ({ entity, handleSubmit, setStep, prevStep }) => {
    const [credentials, setCredentials] = useState({
      email: '',
      username: '',
      password: '',
    });
    const [emailValid, setEmailValid] = useState(true);
    const [touched, setTouched] = useState(false);
    const usernameBasedEmrs = ['Waystar', 'PTPracticePro', 'Jane', 'DrChrono', 'Athena', 'Modmed', 'eClinicalWorks', 'WebPt', 'Raintree', 'Experity'];
    const entityName = entity.name || entity.emrName;

    const checkEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setCredentials(prev => ({ ...prev, [name]: value }));
      if (name === 'email') {
          if (touched) setEmailValid(checkEmailValid(value));
      }
    };

    const handleEmailBlur = () => {
        setTouched(true);
        setEmailValid(checkEmailValid(credentials.email));
    };

    const checkFormValid = () => {
      if (usernameBasedEmrs.includes(entityName)) {
          return credentials.username.length > 0 && credentials.password.length > 0;
      }
      return emailValid && credentials.email.length > 0 && credentials.password.length > 0;
    };

    const handleSubmission = () => {
      if (checkFormValid()) {
        if (usernameBasedEmrs.includes(entityName)) {
          handleSubmit(credentials.username, credentials.password);
        } else {
              handleSubmit(credentials.email, credentials.password);
        }
      }
    };

    const getImageHeightClass = (entityName) => {
      switch (entityName) {
        case 'OncoEMR':
          return 'cobalt-h-16 cobalt-w-auto';
        case 'eClinicalWorks':
          return 'cobalt-h-7 cobalt-w-auto';
        case 'Jane':
          return 'cobalt-h-24 cobalt-w-auto';
        default:
          return 'cobalt-h-10 cobalt-w-auto';
      }
    };

  return (
    <>
      <div className={`cobalt-flex cobalt-items-center cobalt-justify-between cobalt-p-4`}>
        <button onClick={() => setStep(prevStep)} className="cobalt-text-gray-500">
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <img src="https://linkassets.blob.core.windows.net/cobalt/cobalt-logo.png" alt="Cobalt Logo" className="cobalt-h-6" />
      </div>

      <div className={`cobalt-flex cobalt-flex-col cobalt-items-start cobalt-mt-12 cobalt-mb-4`}>
        <div className={`cobalt-flex cobalt-flex-row cobalt-items-start cobalt-mb-12`}>  
          <div className="cobalt-flex-shrink-0 cobalt-mr-2" style={{ display: 'flex', alignItems: 'center' }}>
            <img 
              src={entity.logoUrl || entity.emrLogoUrl} 
              alt={`${entityName} Logo`} 
              className={`cobalt-object-contain ${getImageHeightClass(entityName)}`}
              style={{ maxWidth: '100%' }}
            />
          </div>
        </div>
        <h1 className="cobalt-text-left cobalt-font-normal">Log into {entityName}</h1>
      </div>
      <div className="cobalt-flex cobalt-flex-col cobalt-items-centers">
              {usernameBasedEmrs.includes(entityName) ? (
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={credentials.username}
                onChange={handleInputChange}
                className="cobalt-mb-2 cobalt-p-2 cobalt-border cobalt-rounded cobalt-w-full"
              />
            ) : (
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={credentials.email}
                onChange={handleInputChange}
                onBlur={handleEmailBlur}
                className={`cobalt-mb-2 cobalt-p-2 cobalt-border cobalt-rounded cobalt-w-full ${!emailValid && touched ? 'cobalt-border-red-500' : ''}`}
              />
            )}
            {!emailValid && touched && <p className="cobalt-text-red-500 cobalt-text-xs cobalt-italic">Please enter a valid email.</p>}
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={credentials.password}
              onChange={handleInputChange}
              className="cobalt-mb-2 cobalt-p-2 cobalt-border cobalt-rounded cobalt-w-full"
            />
          <button
              disabled={!checkFormValid()}
              onClick={handleSubmission}
              className={`cobalt-p-2 cobalt-w-full cobalt-bg-primary cobalt-text-white cobalt-rounded cobalt-h-12 ${checkFormValid() ? 'hover:cobalt-bg-primary-dark cobalt-transition-colors cobalt-duration-200' : 'disabled:cobalt-opacity-50'}`}
            >
              Submit
          </button>
      </div>
    </>
  );
};

export default LogIn;